import React, { useEffect, useState } from 'react'
import './contact.css';
import { FaRegUser, FaHome, FaPhoneAlt } from 'react-icons/fa';
import { TfiEmail } from 'react-icons/tfi';
import { FiPhone } from 'react-icons/fi';
import { BiMessageAltDots } from 'react-icons/bi';
import { IoMdMail } from 'react-icons/io';

const Contact = (props) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  // useeffect
  useEffect(() => {
    setDate(new Date().toLocaleDateString());
    setTime(new Date().toLocaleTimeString());
  })
  // toast

  function Submit(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append('Name', name);
    formData.append('Phone', phone);
    formData.append('Email', email);
    formData.append('Message', message);
    formData.append('Date', date);
    formData.append('Time', time);

    fetch(
      'https://script.google.com/macros/s/AKfycbxsN6Qzc9wlDl-w5aPlidOmdFyMQjQ7XRa2ESAfsjtsEz9w2MulZqGsfPKex8QBrj2umA/exec',
      {
        method: 'POST',
        body: formData,
      }
    )
      .then((res) =>alert('ok') )
      .catch((error) => {

      });

  }
  // contact end
  return (
    <>
    
      {props.data.map((data,index) => {
        return (
          <div key={index}>
          
            <div className="contact-parent-v1 parent"  >
              <div className="contact-container container">

                <div className="contact-left"  >

                  <div className="form_wrapper">
                    <div className="form_main">
                      <div className="box1">
                        <h3>{data.contact}</h3>
                      </div>
                      <form className="form" onSubmit={(e) => Submit(e)}>
                        <div className="input-icon-wrap">
                          <span className="input-icon"><FaRegUser /></span>
                          <input type="text" name="uname" id="form-name" placeholder={props.state? 'नाव' : "Name"} value={name}
                            onChange={(e) => setName(e.target.value)} required
                          />
                        </div>
                        <div className="input-icon-wrap">
                          <span className="input-icon"><TfiEmail /></span>
                          <input type="email" name="email" id="form-email" placeholder={props.state?"ई-मेल ":"Email"} value={email}
                            onChange={(e) => setEmail(e.target.value)} required
                          />
                        </div>
                        <div className="input-icon-wrap">
                          <span className="input-icon" ><FiPhone /></span>
                          <input type="number" name="phone" id="form-phone" placeholder={props.state? "फोन":"Phone"} value={phone}
                            onChange={(e) => setPhone(e.target.value)} required />
                        </div>

                        <div className="input-icon-wrap">
                          <span className="input-icont"><BiMessageAltDots /></span>
                          <textarea
                            placeholder={props.state? "संदेश":"Message"}
                            name="Message"
                            id=""
                            rows="4"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                          ></textarea>
                        </div>

                        <button className='btn' type="submit">{props.state? "पाठवा":"Send"}</button>
                      </form>
                    </div>
                  </div>

                </div>

                <div className="contact-right" >
                  <div className="contact-right-container">
                    <div className='contact-address-box'>

                      <div className="contact_icon_container">
                        <h2><span className='contact-icons'><FaHome /></span><span className='contact-h'>{props.state? "पत्ता" : 'Address'}</span>
                        </h2>
                        <p className='contactp'>{data.latur}</p>
                      </div>
                      <div className="contact_icon_container">
                        <h2><span className='contact-icons'><FaPhoneAlt /></span> <span className='contact-h'>{props.state? "फोन":"Phone"}</span></h2>
                        <p className='contactp'>02384 243 788</p>
                      </div>
                      <div className="contact_icon_container">
                        <h2><span className='contact-icons'><IoMdMail /></span><span className='contact-h'>{props.state?"ई-मेल ":"Email"} </span></h2>
                        <p className='contactp'>Contact@arvindpatilnilangekar.com</p>
                      </div>
                    </div>


                  </div>

                </div>

              </div>
            </div>
          </ div>
        )
      })}
    </>
  )
}

export default Contact