import React from 'react'
import './socialfeed.css'
import { FacebookEmbed } from 'react-social-media-embed';
import { InstagramEmbed } from 'react-social-media-embed';
import { LinkedInEmbed } from 'react-social-media-embed';
const SocialFeed = (props) => {
  return (
    <>
      <div className="social-parent">
        <div className="social-container">
        {props.state ?
                <h2>
                  सोशल <span>मीडिया 
                  </span>
                </h2>
                :
                <h2>
                  Social <span>Media
                  </span>
                </h2>
              }
          <div className="card-box">
            <div className="card">
              <h3> Facebook</h3>
              <div style={{ display: 'flex', justifyContent: 'center', alignitems: 'center' }}>
                <FacebookEmbed url="https://www.facebook.com/arvindpatilnilangekarofficial/posts/164028263449763" width={300}
                  height={450} />
              </div>
            </div>
            <div className="card">
              <h3>Instagram</h3>
              <div style={{ display: 'flex', justifyContent: 'center' }}>

                <InstagramEmbed url="https://www.instagram.com/p/CyzhmRGNpse/" width={350}
                  height={450} />
              </div>
            </div>
            <div className="card">
              <h3>Linkedin</h3>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <LinkedInEmbed
                  url="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7082986774460776448"
                  postUrl="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7082986774460776448"
                  width={300}
                  height={450}
                />
              </div>
            </div>
          </div>

        </div>
      </div>
    </>

  )
}

export default SocialFeed