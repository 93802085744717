import React, { useEffect, useState } from 'react'
import './blog.css'
import { Card,} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { InfinitySpin } from 'react-loader-spinner'
const Blog = (props) => {
  const [cardData, setCardData] = useState([])
  const [loading, setloading] = useState(false)
  const fetchData = async (baseUrl) => {
    try {
      const response = await fetch(baseUrl);
      const json = await response.json();
      setCardData(json)
      setloading(true)
    } catch (error) {
    }
  };
  // 
  const baseUrl = "https://api.arvindpatilnilangekar.com/api";
  useEffect(() => {
    fetchData(baseUrl + `${props.state ? "/blogs/language/3" : "/blogs/language/1"}`);
  }, [props.state])
  return (
    <>
      <Helmet>
        <title>Our Insights: Read, Learn, and Engage with Our Blog

        </title>
        <meta name='description' content='Dive into our blog for in-depth insights, stories, and updates on various topics ranging from community projects to local events. Stay informed and be inspired.

'/>
        <meta name='keywords' content='Insights, Stories, Updates, Community Projects, Local Events, News, Inspiration, Informative Posts
'/>

      </Helmet>
      <div className="action-parent">
        <div className="action">
          {props.state ? <h1>ब्लॉग्स</h1> : <h1>Blogs</h1>}
        </div>
        {loading ?
          <div className="card-parent">
            {cardData.map((card, index) => {
              return (
                <Card key={index}>
                  <Card.Img variant="top" src={card.image} alt='Arvind Patil Nilangekar Blogs'/>
                  <Card.Body>
                    <Card.Title>{card.title}</Card.Title>
                    <Link to={`/blogs/${card.id}`} className='btn' variant="primary">Read More</Link>
                  </Card.Body>
                </Card>
              )
            })}
          </div> : <div className='loader_spinner'>  <InfinitySpin
            width='200'
            color="#f97d09"
          /> 
          </div>
          }
      </div>
    </>
  )
}

export default Blog