import React, { useEffect, useState } from 'react'
import "./bloglayout.css"
import { Link, useParams } from 'react-router-dom'
const BlogLayout = (props) => {
    const [blog, setblog] = useState([])
    const { id } = useParams()
    const fetchData = async (baseUrl) => {
        try {
            const response = await fetch(baseUrl);
            const json = await response.json();
            setblog(json)
           
        } catch (error) {
        }
    };
    const baseUrl = "https://api.arvindpatilnilangekar.com/api"
    useEffect(() => {
        fetchData(baseUrl + '/blogs/' + id + '/')
    }, [])
    const mydate = new Date(blog.date);
    const formattedDate = mydate.toLocaleDateString();
    const formattedTime = mydate.toLocaleTimeString();
    return (
        <>

            <div className='bloglayout'>
                <div className="blog-container">


                    <div className="layout_blog">
                        <div className="layout_top">
                            <div className="layout_img">
                                <img src={blog.image} alt="Arvind Patil Nilangekar Blog Details" />
                            </div>
                            <div className="layout_details">
                                <p className="layout_date"><span>Publish Date</span>  <span> {formattedDate} </span></p>
                                <p className="layout_time"><span>Publish Time </span><span>{formattedTime}</span></p>
                                <Link className='btn blog_btn' to="/blogs">All Blogs</Link>
                            </div>
                        </div>
                        <div className="layout_bottom">
                            <h1>{blog.title}</h1>
                            <p dangerouslySetInnerHTML={{ __html: blog.blog_content }} />

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogLayout