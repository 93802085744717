import React, { useEffect } from "react";
import './papercutout.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import img2 from "../images/media/1.webp"
import img3 from "../images/media/2.webp"
import img4 from "../images/media/3.webp"
import img5 from "../images/media/4.webp"
import img6 from "../images/media/5.webp"
import img7 from "../images/media/6.webp"
import img8 from "../images/media/paper_cutout1-1.webp"
import img9 from "../images/media/paper_cutout2.webp"
import img10 from "../images/media/paper_cutout3.webp"
import img11 from '../images/media/paper_cutout4.webp'
import img12 from '../images/media/paper_cutout5.webp'
import { Helmet } from "react-helmet";


// import img43 from "../../images/photogallery/g43.jpg"
const { useState } = React;

//IMAGES
//you can also import a local file, the syntax would look like:
//import image1 from './images/imagename.jpg'
const image2 = img2;
const image3 = img3;
const image4 = img4;
const image5 = img5;
const image6 = img6;
const image7 = img7;
const image8 = img8;
const image9 = img9;
const image10 = img10;
const image11 = img11;
const image12 = img12;

//IMAGE ARRAY
const images = [image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12];


//MAIN APP COMPONENT
export default function App(props) {
  useEffect(() => {


    // Initialize AOS with your desired options
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      easing: 'ease-in-out', // Animation easing
    });
  }, []);
  return (
    <>
      
      <div className="gallery-c">

        {props.data.map((data,index) => {
          return (
            <h1 data-aos="zoom-in" data-aos-delay="200" key={index}>{data.onews} <span>{data.events}</span> </h1>
          )
        })}
        <ImageGallery />
      </div>
    </>

  );
}


//MAIN LIGHTBOX
//Holds Images Cards and Lightbox
//this is where all of our logic will live
function ImageGallery() {
  const [imageToShow, setImageToShow] = useState("");
  const [lightboxDisplay, setLightBoxDisplay] = useState(false);

  //looping through our images array to create img elements
  const imageCards = images.map((image) => (
    <img key={image} className="image-card" onClick={() => showImage(image)} src={image} alt={image} />
  ));

  //function to show a specific image in the lightbox, amd make lightbox visible
  const showImage = (image) => {
    setImageToShow(image);
    setLightBoxDisplay(true);
  };

  //hide lightbox
  const hideLightBox = () => {
    setLightBoxDisplay(false);
  };

  //show next image in lightbox
  const showNext = (e) => {
    e.stopPropagation();
    let currentIndex = images.indexOf(imageToShow);
    if (currentIndex >= images.length - 1) {
      setLightBoxDisplay(false);
    } else {
      let nextImage = images[currentIndex + 1];
      setImageToShow(nextImage);
    }
  };

  //show previous image in lightbox
  const showPrev = (e) => {
    e.stopPropagation();
    let currentIndex = images.indexOf(imageToShow);
    if (currentIndex <= 0) {
      setLightBoxDisplay(false);
    } else {
      let nextImage = images[currentIndex - 1];
      setImageToShow(nextImage);
    }
  };

  return (
    <>
       <Helmet>
        <title>Arvind Patil Nilangkear: Staying Informed with News and Paper Cutouts

        </title>
        <meta name='description' content='Stay up to date with the News and paper cutouts. Read more about Arvind patil nilangkear visit this page.

'/>
        <meta name='keywords' content='news,arvind patil, paper cutouts

'/>
        
      </Helmet>

      <div className="card_box">{imageCards}</div>
      {
        lightboxDisplay ?
          <div id="lightbox" onClick={hideLightBox}>
            <button className="clickbtn" onClick={showPrev}>⭠</button>
            <img id="lightbox-img" src={imageToShow} alt="Arvind Patil Nilangekar Media Mentions" />
            <button className="clickbtn" onClick={showNext}>⭢</button>
          </div>
          : ""
      }




    </>
  )
}


// export default PhotoGallery