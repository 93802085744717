import React from 'react'
import CountUp from 'react-countup';
import "./countersocial.css"
const Counter = (data) => {
    return (
        <div className='counter_wrapper'>
            <div className="Counter_top"><CountUp end={data.number} duration={data.duration} />
            <span className='Counter_sufix'>{data.sufix}</span></div>
            <p className='Counter_p'>{data.text}</p>
        </div>
    )
}

export default Counter