import React, { useEffect, useState } from 'react'
import "./socialwork.css"
import Counter from './CounterSocial'
import { Helmet } from 'react-helmet'
import { useInView } from 'react-intersection-observer'
const SocialWork = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible1, setIsVisible1] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible3, setIsVisible3] = useState(false);
  const [isVisible4, setIsVisible4] = useState(false);
  const [isVisible5, setIsVisible5] = useState(false);
  const { ref, inView } = useInView({
      triggerOnce: true,
  });
  const { ref:ref1, inView:inView1 } = useInView({
      triggerOnce: true,
  });
  const { ref:ref2, inView:inView2 } = useInView({
      triggerOnce: true,
  });
  const { ref:ref3, inView:inView3 } = useInView({
      triggerOnce: true,
  });
  const { ref:ref4, inView:inView4 } = useInView({
      triggerOnce: true,
  });
  const { ref:ref5, inView:inView5 } = useInView({
      triggerOnce: true,
  });
  useEffect(() => {
    const fragmentIdentifier = window.location.hash;
    if (fragmentIdentifier) {
      const elementId = fragmentIdentifier.slice(1);
      const targetElement = document.getElementById(elementId);
  
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  
    if (inView) {
      setIsVisible(true);
    }
    if (inView1) {
      setIsVisible1(true);
    }
    if (inView2) {
      setIsVisible2(true);
    }
    if (inView3) {
      setIsVisible3(true);
    }
    if (inView4) {
      setIsVisible4(true);
    }
    if (inView5) {
      setIsVisible5(true);
    }
  }, [inView, inView1, inView2, inView3, inView4,inView5]);
  
  return (
    <>
       <Helmet>
        <title>Empowering Communities: Drushti Abhiyan, Project Anandi, Shivjayanti, COVID Work, Farmer Initiatives, Vaccination Efforts, and Akka Foundation

        </title>
        <meta name='description' content='Explore our diverse initiatives, from Drushti Abhiyan to Project Anandi, as we work together to celebrate Shivjayanti, support COVID relief, empower farmers, and ensure effective vaccinations in our community.

'/>
        <meta name='keywords' content='Drushti abhiyan, project anandi, vaccination task force, shivjayanti, covid work, farmer initiatives, akka foundation
'/>
        
      </Helmet>
      {props.data.map((data,index) => {
        return (
          <div className='socialwork' key={index}>
            {/* akka foundation */}
            <div className="socialbox box1" id='akkafoundation'>
              <div className="bg"></div>
              <div className="content-box">
                <div className="blank"></div>
                <div className="content">
                  <h2 className='social-heading' >
                    {data.soch1}
                  </h2>
                  <p className="social-body" dangerouslySetInnerHTML={{ __html: data.soct1 }} />
                  <div className="counter">
                  </div>
                  <a className="btn" href="https://akkafoundation.in" target="_blank"  rel="noreferrer"> {data.soct001}</a>
                </div>
              </div>
            </div>
            {/* drusti 1 */}
            <div className="socialbox box2"  id='drushti'>
              <div className="bg"></div>
              <div className="content-box">
                <div className="blank"></div>
                <div className="content">
                  <h2 className='social-heading' >
                    {data.soch2}
                  </h2>
                  <p className="social-body" dangerouslySetInnerHTML={{ __html: data.soct2 }} />
              
                    <ul>
                      {data.socul2}
                      <li>{data.socli2o1}</li>
                      <li>{data.socli2o2}</li>
                      <li>{data.socli2o3}</li>
                    </ul>
               

                  <div className="counter" ref={ref1}>
                    {isVisible1 && <>
                    <Counter number='40' duration='5' sufix='' text={data.soccount2o1} />
                    <Counter number='10605' duration='5' sufix='' text={data.soccount2o2} />
                    <Counter number='3399' duration='5' sufix='' text={data.soccount2o3} />
                    <Counter number='720' duration='5' sufix='' text={data.soccount2o4} /></>}
                  </div>
                  {/* <Link className="btn" > Read More</Link> */}
                </div>


              </div>
            </div>
            {/* drusti 2 */}
            <div className="socialbox box3" >
              <div className="bg"></div>
              <div className="content-box">
                <div className="blank"></div>
                <div className="content">
                  <h2 className='social-heading' >
                    {data.soch3}
                  </h2>
                  <p className="social-body" dangerouslySetInnerHTML={{ __html: data.soct3 }} />

                  <div className="counter" ref={ref}>
                    {isVisible && <>
                    <Counter number='30' duration='5' sufix='' text={data.soccount3o1} />
                    <Counter number='4385' duration='5' sufix='' text={data.soccount3o2} />
                    <Counter number='2060' duration='5' sufix='' text={data.soccount3o3} />
                    <Counter number='339' duration='5' sufix='' text={data.soccount3o4} /> </>}
                  </div>
                  {/* <Link className="btn" > Read More</Link> */}
                </div>


              </div>
            </div>
            {/* drusti 3 */}
            <div className="socialbox box4">
              <div className="bg"></div>
              <div className="content-box">
                <div className="blank"></div>
                <div className="content">
                  <h2 className='social-heading' >
                    {data.soch4}
                  </h2>
                  <p className="social-body" dangerouslySetInnerHTML={{ __html: data.soct4 }} />

                  <div className="counter" ref={ref2}> {isVisible2 && <>
                    <Counter number='30' duration='5' sufix='' text={data.soccount4o1} />
                    <Counter number='3229' duration='5' sufix='' text={data.soccount4o2} />
                    <Counter number='1823' duration='5' sufix='' text={data.soccount4o3} />
                    <Counter number='293' duration='5' sufix='' text={data.soccount4o4} /> </> }
                  </div>
                  {/* <Link className="btn" > Read More</Link> */}
                </div>


              </div>
            </div>
            {/* drusti 4 */}
            <div className="socialbox box5">
              <div className="bg"></div>
              <div className="content-box">
                <div className="blank"></div>
                <div className="content">
                  <h2 className='social-heading' >
                    {data.soch5}
                  </h2>
                  <p className="social-body" dangerouslySetInnerHTML={{ __html: data.soct5 }} />
                  <div className="counter" ref={ref3}> {isVisible3 && <>
                    <Counter number='32' duration='5' sufix='' text={data.soccount5o1} />
                    <Counter number='3439' duration='5' sufix='' text={data.soccount5o2} />
                    <Counter number='263' duration='5' sufix='' text={data.soccount5o3} />
                    <Counter number='5606' duration='5' sufix='' text={data.soccount5o4} /></>}
                  
                  </div>
                  <p>*&nbsp;{props.state?<span>आतापर्यंतची आकडेवारी</span>:<span>Data Updated Till</span>} &nbsp;27/10/2023</p>
                  {/* <Link className="btn" > Read More</Link> */}
                </div>


              </div>
            </div>
            {/* drushti abhiyan total*/}
            <div className="socialbox box9">
              <div className="bg"></div>
              <div className="content-box">
                <div className="blank"></div>
                <div className="content">
                  <h2 className='social-heading' >
                    {data.sco}
                  </h2>
                  <p className="social-body" dangerouslySetInnerHTML={{ __html: data.sco1 }} />
                  <div className="counter" ref={ref4}> {isVisible4 && <>
                    <Counter number='132' duration='5' sufix='' text={data.sco0} />
                    <Counter number='24648' duration='5' sufix='' text={data.sco2} />
                    <Counter number='1617' duration='5' sufix='' text={data.sco3} />
                    <Counter number='10721' duration='5' sufix='' text={data.sco4} /></>}
                  </div>
                  <p>*&nbsp;{props.state?<span>आतापर्यंतची आकडेवारी</span>:<span>Data Updated Till</span>} &nbsp;27/10/2023</p>
                </div>
              </div>
            </div>
            {/* project anadi */}
            <div className="socialbox box6"  id='projectanandi'>
              <div className="bg"></div>
              <div className="content-box">
                <div className="blank"></div>
                <div className="content">
                  <h2 className='social-heading' >
                    {data.soch6}
                  </h2>
                  <p className="social-body" dangerouslySetInnerHTML={{ __html: data.soct6 }} />
                
                    <ul>
                      {data.socul6}
                      <li>{data.socli6o1}</li>
                      <li>{data.socli6o2}</li>
                      <li>{data.socli6o3}</li>
                      <li>{data.socli6o4}</li>
                      <li>{data.socli6o5}</li>
                    </ul>
                    <div className="counter" ref={ref5}> {isVisible5 && <>
                    <Counter number='17520' duration='5' sufix='' text={data.anandic1} />
                    <Counter number='232' duration='5' sufix='' text={data.anandic2} />
                    <Counter number='233' duration='5' sufix='' text={data.anandic3} />
                    <Counter number='117' duration='5' sufix='' text={data.anandic4} /> </>}
                  </div>
                  <p>*&nbsp;{props.state?<span>आतापर्यंतची आकडेवारी</span>:<span>Data Updated Till</span>} &nbsp;27/10/2023</p>
                  <a className="btn" href="https://akkafoundation.in/project-anandi" target="_blank"  rel="noreferrer">{data.socli6o8}</a>
                </div>


              </div>
            </div>
            {/* taskforce */}
            <div className="socialbox box7" id='vaccination'>
              <div className="bg"></div>
              <div className="content-box">
                <div className="blank"></div>
                <div className="content">
                  <h2 className='social-heading' >
                    {data.soch7}
                  </h2>
                  <p className="social-body" dangerouslySetInnerHTML={{ __html: data.soct7 }} />
                  {/* <Link className="btn" > Read More</Link> */}
                </div>


              </div>
            </div>
                        {/* corona */}
                        <div className="socialbox box18">
              <div className="bg"></div>
              <div className="content-box">
                <div className="blank"></div>
                <div className="content">
                  <h2 className='social-heading' >
                    {data.soch11}
                  </h2>

                  <p className="social-body" dangerouslySetInnerHTML={{ __html: data.soct11 }} />
                  <ul>
                    {data.socul11}
                    <li>{data.socli11o1}</li>
                    <li>{data.socli11o2}</li>
                    <li>{data.socli11o3}</li>
                    <li>{data.socli11o4}</li>
                    <li>{data.socli11o5}</li>
                    <li>{data.socli11o6}</li>
                    <li>{data.socli11o7}</li>
                    <li>{data.socli11o8}</li>
                    <li>{data.socli11o9}</li>
                    <li>{data.socli11o10}</li>
                    <li>{data.socli11o11}</li>
                  </ul>
                  {/* <Link className="btn" > Read More</Link> */}
                </div>
              </div>
            </div>
            {/* samarth booth */}
            {/* <div className="socialbox box8">
              <div className="bg"></div>
              <div className="content-box">
                <div className="blank"></div>
                <div className="content">
                  <h2 className='social-heading' >
                    {data.soch8}
                  </h2>
                  <p className="social-body" dangerouslySetInnerHTML={{ __html: data.soct8 }} />
                  <ul>
                    <li>{data.socul8}</li>
                    <li>{data.socli8o1}</li>
                    <li>{data.socli8o2}</li>
                    <li>{data.socli8o3}</li>
                    <li>{data.socli8o4}</li>
                    <li>{data.socli8o5}</li>
                    <li>{data.socli8o6}</li>
                  </ul>
                  <div className="counter">
                    <Counter number='98.81' duration='5' sufix='%' text={data.soccount8o1} />
                    <Counter number='90.02' duration='5' sufix='%' text={data.soccount8o2} />
                    <Counter number='62.50' duration='5' sufix='%' text={data.soccount8o3} />
                  </div>
                </div>


              </div>
            </div> */}
            {/* booth sasaktikaran */}
            {/* <div className="socialbox box9">
              <div className="bg"></div>
              <div className="content-box">
                <div className="blank"></div>
                <div className="content">
                  <h2 className='social-heading' >
                    {data.soch9}
                  </h2>
                  <p className="social-body" dangerouslySetInnerHTML={{ __html: data.soct9 }} />
                </div>
              </div>
            </div> */}
            {/* shivjayanti */}
            <div className="socialbox box10"  id='shivjayanti'>
              <div className="bg"></div>
              <div className="content-box">
                <div className="blank"></div>
                <div className="content">
                  <h2 className='social-heading' >
                    {data.soc10}
                  </h2>
                  <p className="social-body" dangerouslySetInnerHTML={{ __html: data.socti10 }} />
                </div>


              </div>
            </div>
            {/* shivjayanti 2018*/}
            <div className="socialbox box11">
              <div className="bg"></div>
              <div className="content-box">
                <div className="blank"></div>
                <div className="content">
                  <h2 className='social-heading' >
                    {data.socti101}
                  </h2>
                  {/* <p className="social-body" dangerouslySetInnerHTML={{ __html: data.socti10}} /> */}
                  <ul>
                    {data.socti102}
                    <li>{data.socti103}</li>
                    <li>{data.socti104}</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* shivjayanti 2019*/}
            <div className="socialbox box12">
              <div className="bg"></div>
              <div className="content-box">
                <div className="blank"></div>
                <div className="content">
                  <h2 className='social-heading' >
                    {data.soc12}
                  </h2>
                  {/* <p className="social-body" dangerouslySetInnerHTML={{ __html: data.socti10}} /> */}
                  <ul>
                    {data.socti121}
                    <li>{data.socti122}</li>
                    <li>{data.socti123}</li>
                    <li>{data.socti124}</li>
                    <li>{data.socti125}</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* shivjayanti 2020*/}
            <div className="socialbox box13">
              <div className="bg"></div>
              <div className="content-box">
                <div className="blank"></div>
                <div className="content">
                  <h2 className='social-heading' >
                    {data.soc13}
                  </h2>
                  <p className="social-body" dangerouslySetInnerHTML={{ __html: data.socti131 }} />
                </div>
              </div>
            </div>
            {/* shivjayanti 2021*/}
            <div className="socialbox box14">
              <div className="bg"></div>
              <div className="content-box">
                <div className="blank"></div>
                <div className="content">
                  <h2 className='social-heading' >
                    {data.soc14}
                  </h2>
                  {/* <p className="social-body" dangerouslySetInnerHTML={{ __html: data.socti10}} /> */}
                  <ul>
                    {data.socti141}
                    <li>{data.socti142}</li>
                    <li>{data.socti143}</li>
                    <li>{data.socti144}</li>
                    <li>{data.socti145}</li>
                    <li>{data.socti146}</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* shivjayanti 2022*/}
            <div className="socialbox box15">
              <div className="bg"></div>
              <div className="content-box">
                <div className="blank"></div>
                <div className="content">
                  <h2 className='social-heading' >
                    {data.soc15}
                  </h2>
                  {/* <p className="social-body" dangerouslySetInnerHTML={{ __html: data.socti10}} /> */}
                  <ul>
                    {data.socti151}
                    <li>{data.socti152}</li>
                    <li>{data.socti153}</li>
                    <li>{data.socti154}</li>
                    <li>{data.socti155}</li>
                    <li>{data.socti156}</li>
                    <li>{data.socti157}</li>
                    <li>{data.socti158}</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* shivjayanti 2023*/}
            <div className="socialbox box16">
              <div className="bg"></div>
              <div className="content-box">
                <div className="blank"></div>
                <div className="content">
                  <h2 className='social-heading' >
                    {data.soc16}
                  </h2>
                  {/* <p className="social-body" dangerouslySetInnerHTML={{ __html: data.socti10}} /> */}
                  <ul>
                    <li>{data.socti1601}</li>
                    <li>{data.socti1602}</li>
                    <li>{data.socti1603}</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* adarsh go */}
            <div className="socialbox box17">
              <div className="bg"></div>
              <div className="content-box">
                <div className="blank"></div>
                <div className="content">
                  <h2 className='social-heading' >
                    {data.soch10}
                  </h2>

                  <p className="social-body" dangerouslySetInnerHTML={{ __html: data.soct10 }} />
                  <ul>
                    {data.socul10}
                    <li>{data.socli10o1}</li>
                    <li>{data.socli10o2}</li>
                    <li>{data.socli10o3}</li>
                    <li>{data.socli10o4}</li>
                    <li>{data.socli10o5}</li>
                  </ul>
                  {/* <Link className="btn" > Read More</Link> */}
                </div>
              </div>
            </div>

            {/* farmers */}
            <div className="socialbox box19">
              <div className="bg"></div>
              <div className="content-box">
                <div className="blank"></div>
                <div className="content">
                  <h2 className='social-heading' >
                    {data.soch120}
                  </h2>

                  <p className="social-body" dangerouslySetInnerHTML={{ __html: data.soct12 }} />
                 
                    <h2>{data.soch12}</h2>
                    <p>{data.soct12}</p>
<br></br>
                    <h2>{data.cohhead12o1}</h2>
                    <p>{data.cohshead12o1}</p>
                    <br></br>
                    <h2>{data.cohhead12o2}</h2>
                    <p>{data.cohshead12o2}</p>
                    <br></br>
                    <h2>{data.cohhead12o3}</h2>
                    <p>{data.cohshead12o3}</p>
                
                  {/* <Link className="btn" > Read More</Link> */}
                </div>
              </div>
            </div>
            {/* akka foundation work */}
            <div className="socialbox box20">
              <div className="bg"></div>
              <div className="content-box">
                <div className="blank"></div>
                <div className="content">
                  <h2 className='social-heading' >
                    {data.soch13}
                  </h2>

                  <p className="social-body" dangerouslySetInnerHTML={{ __html: data.soct13 }} />
                  <ul>
                    {data.socul13}
                    <li>{data.socli13o1}</li>
                    <li>{data.socli13o2}</li>
                  </ul>
                  {/* <Link className="btn" > Read More</Link> */}
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default SocialWork