import React, { useEffect} from 'react'
import "./mainpage.css"
import { Link } from 'react-router-dom';
// icons 
//  carousels
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
// carousels end
import { Typewriter } from 'react-simple-typewriter'
//
// gallery image imports 
import pic1 from "../images/gallery/g2.webp"
import pic2 from "../images/gallery/g21.webp"
import pic3 from "../images/gallery/g23.webp"
import pic4 from "../images/gallery/g7.webp"
import pic5 from "../images/gallery/g26.webp"
import pic6 from "../images/gallery/g27.webp"
import pic7 from "../images/gallery/g3.webp"
import { useState } from 'react';
import HeroCarousel from './HeroCarousel';
import SocialFeed from './SocialFeed';
import Hero from './Hero';
import sam from "../images//herosection/hero6.webp";
import vac from "../images/empowerment/vaccination.webp"
import akka from "../images/empowerment/akkafoundation.webp"
import drushti from "../images/social/drushti.webp"
import projectanandi from "../images/social/projectanandi.webp"
import shivjayanti from "../images/social/shivjayanti2020.webp"
import { InfinitySpin } from 'react-loader-spinner'
import AOS from 'aos';
// 
const Mainpage = (props) => {
  const [bdata, setbdata] = useState([])
  const [loader, setloader] = useState(false)
  const fetchData = async (baseUrl) => {
    try {
      const response = await fetch(baseUrl);
      const json = await response.json();
      setbdata(json)
      setloader(true)
    } catch (error) {
    }
  };
  const baseUrl = "https://api.arvindpatilnilangekar.com/api";
  useEffect(() => {
    AOS.init();
    fetchData(baseUrl + `${props.state ? "/blogs/language/3" : "/blogs/language/1"}`);
  }, [props.state]);
  return (
    <>
      {props.data.map((data, index) => {
        return (
          <div className="mainpage-wrapper" key={index}>
            {/* hero section */}
            <div className="hero_carousel">
              <HeroCarousel state={props.state} />
            </div>
            {/* fourth section */}
            <div className="fourth_section">

              <Hero data={props.data} state={props.state} setstate={props.setstate} />
            </div>
            {/* fourth end */}
            {/* second section */}
            <div className="second-section">
              {props.state ?

                <h2>
                  सशक्त <span>
                    <Typewriter
                      words={['बूथ', 'भाजपा', 'महाराष्ट्र', 'भारत']}
                      cursor
                      loop={true}
                      cursorStyle='|'
                      typeSpeed={100}
                      deleteSpeed={50}
                      delaySpeed={1000}
                    /></span>
                </h2>
                :
                <h2>
                  Empower <span>
                    <Typewriter
                      words={['Booth', 'BJP', 'Maharastra', 'India']}
                      cursor
                      loop={true}
                      cursorStyle='|'
                      typeSpeed={100}
                      deleteSpeed={50}
                      delaySpeed={1000}
                    /></span>
                </h2>}
              <div className="second-main" data-aos="fade-up" data-aos-delay="200" >

                <div className="card" style={{ backgroundImage: `url(${sam})` }} >
                  <Link className='btn btn-hover' to="/samarth-booth-abhiyan">
                    {data.boothabhiyan}
                  </Link>
                  <div className="grad"></div>
                </div>
                <div className="card" style={{ backgroundImage: `url(${akka})` }} >
                  <Link to='/social#akkafoundation' className='btn btn-hover'>
                    {data.akka}
                  </Link>
                  <div className="grad"></div>
                </div>
                <div className="card" style={{ backgroundImage: `url(${vac})` }} >
                  <Link to="/social#vaccination" className='btn btn-hover'>
                    {data.vaccination}
                  </Link>
                  <div className="grad"></div>
                </div>
                <div className="card" style={{ backgroundImage: `url(${drushti})` }}>
                  <Link to='/social#drushti' className='btn btn-hover'>
                    {data.drushti}
                  </Link>
                  <div className="grad"></div>
                </div>
                <div className="card" style={{ backgroundImage: `url(${projectanandi})` }} >
                  <Link to='/social#projectanandi' className='btn btn-hover'>
                    {data.projectanandi}
                  </Link>
                  <div className="grad"></div>
                </div>
                <div className="card" style={{ backgroundImage: `url(${shivjayanti})` }} >
                  <Link to="/social#shivjayanti" className='btn btn-hover'>
                    {data.shivjayanti}
                  </Link>
                  <div className="grad"></div>
                </div>
              </div>
            </div>
            {/* gallery section */}
            {/* comment 4 */}
            <div className="gallery_section">
              <div className="gallery_main">
                {props.state ? <h2 className="gtop"><span>गॅलरी</span></h2> : <h2 className="gtop">Our <span>Gallery</span></h2>}
                <Link to='/gallery' className="parent">
                  <div className="gallery_box gallery_div1" data-aos="fade-right" data-aos-delay="1200" style={{ backgroundImage: `url(${pic4})` }}> </div>
                  <div className="gallery_box gallery_div2" data-aos="fade-right" data-aos-delay="1400" style={{ backgroundImage: `url(${pic2})` }}> </div>
                  <div className="gallery_box gallery_div3" data-aos="fade-right" data-aos-delay="1600" style={{ backgroundImage: `url(${pic3})` }}> </div>
                  <div className="gallery_box gallery_div4" data-aos="fade-down" data-aos-delay="2200" style={{ backgroundImage: `url(${pic1})` }}> </div>
                  <div className="gallery_box gallery_div5" data-aos="fade-up" data-aos-delay="1800" style={{ backgroundImage: `url(${pic5})` }}> </div>
                  <div className="gallery_box gallery_div6" data-aos="fade-down" data-aos-delay="2400" style={{ backgroundImage: `url(${pic6})` }}> </div>
                  <div className="gallery_box gallery_div7" data-aos="fade-up" data-aos-delay="2600" style={{ backgroundImage: `url(${pic7})` }}> </div>
                </Link>
              </div>
            </div>
            {/* gallery ends */}
            {/* last section */}
            <div className="third-section">
              {props.state ?
                <h2>
                  ताज्या <span>घडामोडी
                  </span>
                </h2>
                :
                <h2>
                  Latest <span>News
                  </span>
                </h2>
              }
              {loader ?
              <Swiper

                navigation={true}
                modules={[Navigation]} 
                className="swiper"
                style={{ width: '100%', maxWidth: '1200px', margin: '0 auto' }}

                breakpoints={{
                  1100: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },

                  900: {
                    slidesPerView: 3,
                    spaceBetween: 60,
                  },

                  600: {
                    slidesPerView: 2,
                    spaceBetween: 60,
                  },
                  300: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },

                }}
              >

                  {bdata.map((data) => {
                    const mydate = new Date(data.date);
                    const formattedDate = mydate.toLocaleDateString();
                    return (


                      <SwiperSlide className='swiperslide' key={data.id}>
                                        
                        <button className='date'>
                          {formattedDate}
                        </button>
                        <img src={data.image} alt="" />
                        <div className="title">
                          {data.title}
                        </div>
                        <div className="div">
                          <Link to={`/blogs/${data.id}`} className=' btn'>
                            Read More
                          </Link>
                        </div> 
                      </SwiperSlide>
                    )
                  }) }
              </Swiper>
              : <div className='loader_spinner'>  <InfinitySpin
              width='200'
              color="#f97d09"
            />
            </div>}
            </div>
            {/* social feed */}
            <div className="social_feed_section">
              <SocialFeed state={props.state} setstate={props.setstate} data={props.data} />
            </div>
          </div>
        )
      })}
    </>
  )
}

export default Mainpage