import React from 'react'
import "./footer.css"
// import logo from "../images/logo.png"
import { Link } from 'react-router-dom'
import { AiFillFacebook, AiFillInstagram, AiFillYoutube } from "react-icons/ai"
import { RiTwitterXFill } from "react-icons/ri"

const Footer = (props) => {
  return (
    <>
    {props.data.map((data,index)=>{return(
    <div className="footer" key={index}>
      <div className="footer_inner">
        <div className="top">
          <div className="part1">
            <div className="fotlogo">
              {/* <div className="ftlogo-img">
                <img src={logo} alt="" />
              </div> */}
              <p>{data.logofname} <br /> {data.logolname} </p>
            </div>
            <div className="part1res">
              {data.finfo}
            </div>
          </div>
          <div className="part part3">
            <div className="part_top">
              <p>{data.quicklinks}</p>
            </div>
            <div className="part_bottom">
              <Link to={"./blog"}>{data.blogs}</Link>
              <Link to={"./news"}>{data.news}</Link>
              <Link to={"./gallery"}>{data.gallery}</Link>
              <Link to={"./social"}>{data.social}</Link>
            </div>
          </div>
          <div className="part part4">
            <div className="part_top">
              <p>{data.contact}</p>
            </div>
            <div className="part_bottom">
              <Link>{data.latur}</Link>
              <Link>02384 243 788</Link>
              <Link>contact@arvindpatilnilangekar.com</Link>
              <p>{data.monsat}: 8:00AM-6:00PM</p>
            </div>
          </div>
        </div>
        <div className="bottom">
          <p className='copy'>Copyright © 2023 Arvind Patil Nilangekar | <Link to='/privacy'> Privacy Policy</Link> | <Link to='/disclaimer'>Disclaimer</Link> </p>
          <div className="icons">
           <a href="https://www.facebook.com/arvindpatilnilangekarofficial" target="_blank"  rel="noreferrer"> <AiFillFacebook /></a>
           <a href="https://www.instagram.com/arvind_patil_nilangekar/?utm_source=ig_embed&ig_rid=152fdf6f-c1ef-4124-bb6a-7921d98fab8a" target="_blank"  rel="noreferrer"> <AiFillInstagram /></a>
           <a href="https://twitter.com/NilangekarPatil" target="_blank"  rel="noreferrer"> <RiTwitterXFill /></a>
           <a href="https://www.youtube.com/@arvindpatilnilangekar" target="_blank"  rel="noreferrer"> <AiFillYoutube /></a>
          </div>
        </div>
      </div>
    </div>
    )})}
    </>
  )
}

export default Footer