import React, { useEffect } from "react";
import "./gallery.css";
import AOS from "aos";
import 'aos/dist/aos.css';
import img1 from "../images/gallery/photogallery/g1.webp"
import img2 from "../images/gallery/photogallery/g2 .webp"
import img3 from "../images/gallery/photogallery/g3.webp"
import img4 from "../images/gallery/photogallery/g4.webp"
import img5 from "../images/gallery/photogallery/g5.webp"
import img6 from "../images/gallery/photogallery/g6.webp"
import img7 from "../images/gallery/photogallery/g7.webp"
import img8 from "../images/gallery/photogallery/g8.webp"
import img9 from "../images/gallery/photogallery/g9.webp"
import img10 from "../images/gallery/photogallery/g10.webp"
import img11 from "../images/gallery/photogallery/g11.webp"
import img12 from "../images/gallery/photogallery/g12.webp"
import img13 from "../images/gallery/photogallery/g13.webp"
import img14 from "../images/gallery/photogallery/g14.webp"
import img15 from "../images/gallery/photogallery/g15.webp"
import img16 from "../images/gallery/photogallery/g16.webp"
import img17 from "../images/gallery/photogallery/g17.webp"
import img18 from "../images/gallery/photogallery/g18.webp"
import img19 from "../images/gallery/photogallery/g19.webp"
import img20 from "../images/gallery/photogallery/g20.webp"
import img21 from "../images/gallery/photogallery/g21.webp"
import img22 from "../images/gallery/photogallery/g22.webp"
import img23 from "../images/gallery/photogallery/g23.webp"
import img24 from "../images/gallery/photogallery/g24.webp"
import img25 from "../images/gallery/photogallery/g25.webp"
import img26 from "../images/gallery/photogallery/g26.webp"
import img27 from "../images/gallery/photogallery/g27.webp"
import img28 from "../images/gallery/photogallery/g28.webp"
import img29 from "../images/gallery/photogallery/g29.webp"
import img30 from "../images/gallery/photogallery/g30.webp"

import img31 from "../images/gallery/photogallery/g31.webp"
import img32 from "../images/gallery/photogallery/g32.webp"
import img33 from "../images/gallery/photogallery/g33.webp"
import img34 from "../images/gallery/photogallery/g34.webp"
import img35 from "../images/gallery/photogallery/g35.webp"
import img36 from "../images/gallery/photogallery/g36.webp"
import img37 from "../images/gallery/photogallery/g37.webp"
import img38 from "../images/gallery/photogallery/g38.webp"
import img39 from "../images/gallery/photogallery/g39.webp"
import img40 from "../images/gallery/photogallery/g40.webp"
import img41 from "../images/gallery/photogallery/g41.webp"
import img42 from "../images/gallery/photogallery/g42.webp"
import img43 from "../images/gallery/photogallery/g43.webp"
import VideoGallery from "./gallery/VideoGallery";
import {AiOutlineArrowRight} from "react-icons/ai"
import {AiOutlineArrowLeft} from "react-icons/ai"
import { Helmet } from "react-helmet";
const { useState } = React;

//IMAGES
//you can also import a local file, the syntax would look like:
//import image1 from './images/imagename.jpg'
const image1 = img1;
const image2 = img2;
const image3 = img3;
const image4 = img4;
const image5 = img5;
const image6 = img6;
const image7 = img7;
const image8 = img8;
const image9 = img9;
const image10 = img10;
const image11 = img11;
const image12 = img12;
const image13 = img13;
const image14 = img14;
const image15 = img15;
const image16 = img16;
const image17 = img17;
const image18 = img18;
const image19 = img19;
const image20 = img20;
const image21 = img21;
const image22 = img22;
const image23 = img23;
const image24 = img24;
const image25 = img25;
const image26 = img26;
const image27 = img27;
const image28 = img28;
const image29 = img29;
const image30 = img30;
const image31 = img31;
const image32 = img32;
const image33 = img33;
const image34 = img34;
const image35 = img35;
const image36 = img36;
const image37 = img37;
const image38 = img38;
const image39 = img39;
const image40 = img40;
const image41 = img41;
const image42 = img42;
const image43 = img43;


//IMAGE ARRAY
const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12, image13, image14, image15, image16, image17, image18, image19, image20, image21, image22, image23, image24, image25, image26, image27, image28, image29, image30, image31, image32, image33, image34, image35, image36, image37, image38, image39, image40, image41, image42, image43];


//MAIN APP COMPONENT
export default function App(props) {
  const [toggle1 , settoggle1] = useState(true)
  const [toggle2 , settoggle2] = useState(false)
  useEffect(() => {


    // Initialize AOS with your desired options
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      easing: 'ease-in-out', // Animation easing
    });
  }, []);
  const gallerytoggle=()=>{
    settoggle1(true)
    settoggle2(false)
    
  }
  const videotoggle=()=>{
    settoggle1(false)
    settoggle2(true)
  }
  return (
    <>
      {/*  */}



      <div className="gallery-c">

        {/* butttons */}
        <div className="gallery-btn-container">
          <button className={toggle1 ? "my-btn" : "sudoactive" } onClick={gallerytoggle}>{props.state ?"फोटो " :'Images'}</button>
          <button className={toggle2 ? "my-btn" : "sudoactive" } onClick={videotoggle}>{props.state ? 'व्हिडीओ' : 'video'}</button>
          <a href="https://stock.arvindpatilnilangekar.com" className="my-btn sudoactive">Stock</a>        
          </div>
        {/* End buttons */}

       


        <ImageGallery data={props.data} toggle1={toggle1} toggle2={toggle2} />

      </div>
    </>
  );
}

function ImageGallery(props) {
  const [imageToShow, setImageToShow] = useState("");
  const [lightboxDisplay, setLightBoxDisplay] = useState(false);

  //looping through our images array to create img elements
  const imageCards = images.map((image) => (
    <img key={image} className="image-card" onClick={() => showImage(image)} src={image} alt={image} />
  ));

  //function to show a specific image in the lightbox, amd make lightbox visible
  const showImage = (image) => {
    setImageToShow(image);
    setLightBoxDisplay(true);
  };

  //hide lightbox
  const hideLightBox = () => {
    setLightBoxDisplay(false);
  };

  //show next image in lightbox
  const showNext = (e) => {
    e.stopPropagation();
    let currentIndex = images.indexOf(imageToShow);
    if (currentIndex >= images.length - 1) {
      setLightBoxDisplay(false);
    } else {
      let nextImage = images[currentIndex + 1];
      setImageToShow(nextImage);
    }
  };

  //show previous image in lightbox
  const showPrev = (e) => {
    e.stopPropagation();
    let currentIndex = images.indexOf(imageToShow);
    if (currentIndex <= 0) {
      setLightBoxDisplay(false);
    } else {
      let nextImage = images[currentIndex - 1];
      setImageToShow(nextImage);
    }
  };


  return (
    <>
      <Helmet>
        <title>Capturing Moments and Memories

        </title>
        <meta name='description' content="Browse through our gallery to relive the beauty, joy, and memories of our events, projects, and community efforts. Explore the visual stories we've captured.

"/>
        <meta name='keywords' content='Photography, Moments, Memories, Visual Stories, Events, Community, Projects, Celebrations, Captured Moments, Highlights

'/>

      </Helmet>
      {props.toggle1 && <>
        {props.data.map((data,index) => { return (<h1 data-aos="zoom-in" data-aos-delay="200" key={index}>{data.photo} <span>{data.gallery}</span></h1>) })}
      <div className="gallery_parent_box">{imageCards}</div>
      {
        lightboxDisplay ?
          <div id="lightbox" onClick={hideLightBox}>
            <button className="clickbtn" onClick={showPrev}><AiOutlineArrowLeft/></button>
            <img id="lightbox-img" src={imageToShow} alt="Arvind Patil Nilangekar Images" />
            <button className="clickbtn" onClick={showNext}><AiOutlineArrowRight/></button>
          </div>
          : ""
      } </>
    }



      {/* //video   */}



     {props.toggle2 && <VideoGallery data={props.data} />} 

    </>
  )
}


// export default PhotoGallery