import React from 'react'
import "./samarth.css"
import Counter from './CounterSocial'
const Samarth = (props) => {
  return (
    <>
      {props.data.map((data,index) => {
        return (
          <>
            <div className='samarthwork-parent' key={index}>
              <h2 className='samarth-heading'>{data.soch8}</h2>
              <div className="samarthbox">

                <div className='samarthwork-left'>
                  <div className="samarthwork-content-box">
                    <div className="samarthwork-content">
                      <p className="samarth-body" dangerouslySetInnerHTML={{ __html: data.boothp }} />
                    </div>
                  </div>
                </div>
                <div className='samarthwork-right'>
                  <div className="samarthwork-right-container">
                  </div>
                </div>
              </div>
            </div>
            <div className='section-gap'></div>

            <div className='feature-parent'>

              <div className='features-container'>
                <h2 className='ul_heading'>{data.boothli}</h2>
                <ul>
                  <li>{data.boothli1}</li>
                  <li>{data.boothli2}</li>
                  <li>{data.boothli3}</li>
                  <li>{data.boothli4}</li>
                  <li>{data.boothli5}</li>
                  <li>{data.boothli6}</li>
                </ul>
              </div>
            </div>

            {/* </div> */}
            {/* <div className='section-gap'></div> */}

            <div className="samarth-counter_parent">
              <div className='samarth-counter-container'>
                 <h2 className='samarth-booth-counter-heading'>{data.boothcounterh}</h2>
                <div className='samarth-counter-box'>
                  <Counter number='98.81' duration='10' sufix='%' text={data.samarthcount1} />
                  <Counter number='90.02' duration='10' sufix='%' text={data.samarthcount2} />
                  <Counter number='62.50' duration='10' sufix='%' text={data.samarthcount3} />
                  {/* <Counter number='98.81' duration='10' sufix='%' text={data.samarthcount4} />
                <Counter number='90.02' duration='10' sufix='%' text={data.samarthcount5} />
                <Counter number='62.50' duration='10' sufix='%' text={data.samarthcount6} /> */}
                </div>
              </div>
            </div>

            <div className='section-gap'></div>

            <div className="sashaktikaran-parent">
              <h2 className='sashaktikaran-heading'> {data.soch9}</h2>
              <div className="sashaktikaran-box">
                <div className='sashaktikaran-left'>
                  <div className='sashaktikaran-left-container'>
                  </div>
                </div>
                <div className='sashaktikaran-right'>
                  <div className="sashaktikaran-content">

                    <p className="sashaktikaran-body" dangerouslySetInnerHTML={{ __html: data.Sashaktikaranp }} />
                  </div>
                </div>
              </div>
            </div>



            {/* </div> */}
          </>
        )
      }
      )}
    </>
  )
}

export default Samarth