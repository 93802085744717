const englishdata = [
    {
        logofname: 'ARVIND DILIPRAO',
        logolname: 'PATIL NILANGEKAR',
        arvind: 'ARVIND',
        patil: 'PATIL',
        nilangekar: 'NILANGEKAR',
        finfo: 'Secretary, Bhartiya Janta Party State Committee, Maharashtra President, Akka Foundation, Nilanga Conveyor, Nilanga Vidhan Sabha',

        //about page
        meet: 'Meet Arvind Diliprao Patil Nilangekar',
        personal: 'Personal Information',
        name: 'Name',
        name1: 'Mr. Arvind Diliprao Patil Nilangekar',
        village: 'Village',
        village1: ' Nilanga (TQ. Nilanga Dist. Latur)',
        bday: 'Date Of Birth',
        bday1: '9 March 1983',
        education: 'Education',
        education1: 'PG Diploma in Urban & Regional Planning (Australia)',
        working: 'Presently Working',
        working1: 'State Secretary, Bharatiya Janata Party , Maharashtra President, Akka Foundation',
        bio: 'Marathi BIO',
        stock: 'Stock Images',

        // navigations
        about: 'About',
        social: 'Social Works',
        news: 'News & Events',
        blogs: 'Blogs',
        gallery: 'Gallery',
        contact: 'Contact Us',
        akka: 'Akka Foundation',
        drushti:'Drushti Abhiyan',
        projectanandi:'Project Anandi',
        shivjayanti:'Shivjayanti',
        aboutus: 'About Us',
        our: 'Our',
        onews: 'News &',
        events: 'Events',
        photo: 'Photo',
        video: "Video",
        // -----end navigations
        call: 'Call',
        email: 'Email',
        tag1: 'Leadership That',
        tag2: 'Inspires Change',
        herosub: 'lorefuisdhfyusdghfsduyfhysdufgsdhfsduygfsduygfsdubghf',
        fullname: 'Mr. ARVIND DILIPRAO PATIL NILANGEKAR',
        herodesc: 'State Secratery,Bhartiya Janta Party, Maharastra',
        // heroabout: 'Important in all aspects, Marathwada’s Latur district has a history of producing great leaders for our country. Nilangekar family from this place is one of them who have dedicated their lives to social service and country. They have given us a young and dynamic leader Arvind Patil Nilangekar.Arvind Patil Nilangekar has a family legacy that he upholds exceptionally well through his easy-going and friendly personality. Starting his work at the grassroots level, Arvind bhaiyya started working for the society with MLA Sambhaji Patil Nilangekar.Today, his dedication and incomparable love for his country have made him a Maharashtra State Secretary in the biggest political party, Bharatiya Janata Party, in the country.',
        heroaboutdetail1: 'Important in all aspects, Marathwada’s Latur district has a history of producing great leaders for our country. Nilangekar family from this place is one of them who have dedicated their lives to social service and country. They have given us a young and dynamic leader Arvind Patil Nilangekar. Arvind Patil Nilangekar has a family legacy that he upholds exceptionally well through his easy-going and friendly personality. Starting his work at the grassroots level, Arvind bhaiyya started working for the society with MLA Sambhaji Patil Nilangekar.Today, his dedication and incomparable love for his country have made him a Maharashtra State Secretary in the biggest political party, Bharatiya Janata Party, in the country Important in all aspects, Marathwada’s Latur district has a history of producing great leaders for our country. Nilangekar family from this place is one of them who have dedicated their lives to social service and country. They have given us a young and dynamic leader Arvind Patil Nilangekar.Arvind Patil Nilangekar has a family legacy that he upholds exceptionally well through his easy-going and friendly personality. Starting his work at the grassroots level, Arvind bhaiyya started working for the society with MLA Sambhaji Patil Nilangekar.Today, his dedication and incomparable love for his country have made him a Maharashtra State Secretary in the biggest political party, Bharatiya Janata Party, in the country.',
        boothabhiyan: 'Samarth Booth Abhiyan',
        vaccination: 'Vaccination Task Force',
        readmore: 'Read More',
        // footer datas 
        quicklinks: 'Quick Links',
        latur: 'Latur, Maharastra',
        monsat: "Monday-Saturday",
        //  akka foundatiion////////////////////////////////////////////////////// 
        soch1: "Akka Foundation",
        soct1: `In 2016, during a severe drought in Latur district, Arvind Patil's mother, Shrimati Rupatai (Akka) Diliprao Patil Nilangekar, took it upon herself to assist the people of Latur. Inspired by Akka's dedication, Shri Arvind Patil Nilangekar established the <a href="https://akkafoundation.in/" target="_blank">AKKA Foundation</a> in 2017, with the initial contributions from his mother's retirement funds. AKKA Foundation kick-started its initial service projects with these funds. Since 2017, more than 300,000 citizens have benefited from the services provided by the Foundation. The foundation has launched several groundbreaking and empathetic programs to address environmental issues, empower women, improve healthcare, promote education, and provide assistance to distressed farming families who rely on the foundation as their lifeline. This has led to a new chapter in the development of various regions in Latur, with active public participation.`,
        soct001: 'Visit Site',
        // drusti abhiyan ////////////////////////////////////////////////////////////////
        soch2: "Drushti Abhiyan 1.0",
        soct2: `Drushti Abhiyan: A Step Towards Eye Health<br><br>

        In India, especially in rural areas, there is a significant prevalence of eye-related health issues. A lack of modern facilities and awareness about eye health has led to a high incidence of visual impairment, particularly conditions like cataracts. To address these eye-related problems in rural areas, especially cataracts, Shri Arvind Patil Nilangekar initiated the ambitious "Drushti Abhiyan" (Vision Campaign). This campaign started on June 20 and ran until September 17, 2022, making it the first-ever eye health program of such magnitude in the history of Latur. The campaign was remarkably popular and made a significant impact in a short period.
        
        `,
        socul2: 'Key features of the campaign include:',
        socli2o1: 'Providing free treatment and guidance for all eye problems.',
        socli2o2: 'Organizing eye camps in various villages.',
        socli2o3: 'Offering free transportation to the camps for people with disabilities and senior citizens.',
        soccount2o1: 'Villages',
        soccount2o2: 'Eye Checkups',
        soccount2o3: 'Free Spectacles',
        soccount2o4: 'Cataract Surgeries',
        // drusti abhiyan 2.0///////////////////////////////////////////////////////////////
        soch3: "Drushti Abhiyan 2.0",
        soct3: "The second phase of the vision campaign was successfully carried out because many people liked it and wanted it to happen, especially those with special needs. We are happy that the campaign was a success. We started the campaign on December 25 to remember Atal Bihari Vajpayee's birthday, and we also celebrate Prime Minister Shri. Narendra Modi's birthday on September 17.",
        soccount3o1: 'Villages',
        soccount3o2: 'Eye Checkups',
        soccount3o3: 'Free Spectacles',
        soccount3o4: 'Cataract Surgeries',
        // section 4
        soch4: "Drushti Abhiyan 3.0",
        soct4: "Following the successful organization of the first and second phases of the Drushti Abhiyan, Drushti Abhiyan 3.0 was organized on the occasion of Swami Vivekananda's birthday (12th January 2023). The planning for this phase was inspired by the birth anniversary of the revered Akka, scheduled to be held on 4th June 2023.",
        soccount4o1: 'Villages',
        soccount4o2: 'Eye examinations',
        soccount4o3: 'Free Spectacles',
        soccount4o4: 'Free eye surgeries',
        // section 5
        soch5: "Drishti Abhiyan 4.0",
        soct5: "At the request of the people, the fourth phase of Drishti Abhiyan has also been started. This phase of the mission will run from June 20 to November 25, 2023. The needy citizens should take advantage of these camps in their villages.",
        soccount5o1: 'Villages',
        soccount5o2: 'spec distributed',
        soccount5o3: 'Total Operations',
        soccount5o4: 'Total patients',
        // Drushti abhiyan Total
        sco: 'Success of Drushti Abhiyan',
        sco1: '',
        sco0: 'Villages',
        sco2: 'Eye examinations',
        sco3: 'Free Spectacles',
        sco4: 'Free eye surgeries',
        // project anandi
        soch6: "Project Anandi",
        soct6: "Project Anandi: A Step for Her<br><br> Menstruation is an extremely important and sensitive issue in every woman's life. Unfortunately, this topic is still shrouded in silence, even today. To break this silence and guide women and young girls on mental and physical health, the Project Anandi initiative has been started. This initiative is the brainchild of Shri. Sambhaji Patil Nilangekar, MLA, and is being carried out with the support of the Akka Foundation.",
        socul6: 'Nature Of Activities:',
        socli6o1: 'Creating awareness in society about "menstruation." ',
        socli6o2: 'Educating young girls in schools and colleges about the use of sanitary pads.',
        socli6o3: 'Providing free sanitary pads to needy women and girls.',
        socli6o4: 'Establishing a sanitary pad production cluster in rural areas.',
        socli6o5: 'Conducting awareness workshops in 2,800 schools.',
        socli6o6: 'Reaching up to 1,40,000 female students.',
        socli6o8: 'Visit Site',
        // vaccination task force
        soch7: "Vaccination Task Force: A Blessing for the Differently Abled",
        soct7: "The Vaccination Task Force was founded in the early stages of the COVID-19 pandemic as a novel effort influenced by the concepts of Arvind Patil Nilangekar. This initiative was of its kind in the state, organized by the Akka Foundation. Over 800 volunteers were recruited by this special task force to go door-to-door and ensure that everyone in the community had received their COVID-19 vaccination. <br>With the successful vaccination of about 12,000 citizens, this initiative proved to be especially helpful for those with disabilities. <br>Nagteerthwadi, a village in Deoni taluka, accomplished this noteworthy feat by becoming the first village in Marathwada and the second in the state to attain a 100% vaccination rate.<br>In recognition of this remarkable initiative, the Deputy Chief Minister of the state, Shri. Devendraji Fadnavis, expressed his appreciation during a virtual interaction (VC).",
        // soccount7o1:'800 volunteers',
        //  soccount7o2:'12000 specially abled vaaccinated',
        //  soccount7o3:'100% vaccinated in Devani ',
        // samartbotth abhiyan
        soct8: "It is a campaign aimed at strengthening the booth-level organization of a political party. It serves as a medium for coordination between party leadership, workers, and the general public. The primary objective is to enhance the organization's structure and importance at the grassroots level. When utilized effectively, booth-level organization can become a significant social, cultural, and community platform for various beneficial activities. <br>The campaigns goal is to strengthen each booth, leading to the overall strengthening of the party. This concept has been emphasized by respected leaders like Prime Minister Narendra Modi and former national president of the party, Amit Shah. The responsibility of coordinating the Samarth Booth Abhiyan in the state was assigned to Shri Arvind Patil Nilangekar on  21 June 2021. <br>Under the guidance of former Chief Minister Devendra Fadnavis and former State President Chandrakant Patil, the Samarth Booth Abhiyan was initiated, commemorating the birth anniversary of the founder of the Rashtriya Swayamsevak Sangh (RSS), Dr. Shyamaprasad Mukherjee, from June 6 to December 25, 2021. The campaign aimed to empower 19,000 power centers and 97,000 booths in the state",
        socul8: 'Notable Features of the Campaign:',
        socli8o1: 'Empowerment of 86,130 booths through this campaign during the period from July 6 to September 17 and from September 17 to December 25, 2021.',
        socli8o2: 'Appointment of 19,169 Shakti Kendra leaders.',
        socli8o3: 'Appointment of 86,466 booth leaders.',
        socli8o4: 'Information about 9,000 committees associated with the opposing side reached more than 1.4 million committee members after the campaign.',
        socli8o5: 'Department-level meetings were held in Western Maharashtra, Konkan, Northern Maharashtra, Marathwada, and Vidarbha regions as part of the campaign.',
        socli8o6: 'Meetings for booth empowerment under the Samarth Booth Campaign - 2 were conducted district-wise.',
        soccount8o1: 'Head Of Shaktikendra – 19169',
        soccount8o2: 'Booth Heads – 86466',
        soccount8o3: 'Booth Committee – 86466',
        // section 9
        soch9: "Booth Sashaktikaran Abhiyan",
        soct9: `Booths serve as a medium of coordination between the party leadership, workers, and common citizens. Therefore, the work of each booth is crucial for the organizational development of the party. When properly utilized, a booth can transform into a social, cultural, and organized platform for community engagement and beneficial activities.<br>
        Recognizing that empowering every booth translates to strengthening the party in the truest sense, the Honorable Prime Minister of the country, Shri Narendra Modi, emphasized that every booth symbolizes the party's strength. Narendra Modi holds the position of the national president of the party. The central campaign 'Booth Sashaktikaran' was launched in May 2022, initiated by Jagat Prakash Nadda ji.<br>
        This campaign directed public representatives to visit every booth, interact with the workers and citizens, and raise awareness about the pro-people initiatives of the central government.<br>
        In Maharashtra, the campaign was launched under the leadership of Chandrashekhar Bawankule. Initially, the state was tasked with strengthening a total of 5311 booths in 106 Assembly and 23 Lok Sabha constituencies, excluding Mumbai.<br>
        However, thanks to the dedicated efforts of respected Bawankule Sahib, this number expanded to 252 Vidhan Sabha and 42 Lok Sabha constituencies. Under Bawankule Sahib's guidance and follow-up, a total of 14 thousand booths were successfully fortified in a short span of time. This endeavor marked the pinnacle of organizational work within the state.<br>
        Praise from the central government:<br>
        The central government's objective was to empower 1 lakh booths nationwide. Maharashtra alone contributed by adding 14 thousand booths to this organizational network. The central government has expressed its appreciation for the work carried out by the Maharashtra BJP. Furthermore, they acknowledged Maharashtra's significant contribution to achieving the target of Rs 1 lakh 
        `,
        // shivjayanti 
        soc10: 'Shivjayanti Celebrations: 6 Years of Continuous Festivities',
        socti10: 'ShivJayanti is a national festival for the entire country. Chhatrapati Shivaji Maharaj is revered as the supreme deity in Hindu religion. The entire world holds him in high regard as the epitome of human values and civility. Even after 350 years, Chhatrapati Shivaji Maharaj continues to inspire all of us. His ideas are guiding our society towards a new direction. The Akka Foundation is committed to sowing the seeds of Shivvichar (Shivajis thoughts) in every individual by awakening these ideals of Maharaj. <br>The organization is implementing innovative artworks, sustainable service initiatives, and efforts to elevate Chhatrapati Shivajis legacy to the world stage through the celebration of Vishwavikrami Shivajayanti. These initiatives have garnered tremendous support, particularly from the elderly and women. As a result, Shivjayanti, celebrated in Nilanga today, has become a matter of pride for Maharashtra, marked by grandeur and divinity.',
        //shivjayanti 2018
        socti101: 'Shivjayanti 2018: World-Famous Rangoli of Chatrapati Shivraya',
        socti102: '50,000 kgs of rangoli were used to create Shivrayas Enthroned image.',
        socti103: 'A 2.5-acre area, equivalent to 1,11,843 sq. feet, was covered by the rangoli.',
        socti104: 'Approximately 1.5 lakh visitors attended.',


        // shivjayanti 2019
        soc12: 'Harith Shivjayanthi Utsav 2019',
        socti121: 'Embarked on the Harit Shiv Janmotsav celebration to promote agricultural and environmental awareness.',
        socti122: 'Crafted a Chatrapati Shivaji Maharaj outline by sowing green seeds, spanning a 6-acre area.',
        socti123: 'The total area covers an extensive 2,40,000 square feet.',
        socti124: 'This exclusive green image is a distinctive global spectacle, being the only one of its kind worldwide.',
        socti125: 'Garnered attention from media outlets across Maharashtra and worldwide, amplifying the initiatives impact in raising environmental consciousness and agricultural appreciation.',


        // shivjayanti 2020
        soc13: 'Shivjayanti 2020: Determination to Combat Drought',
        socti131: 'Commitment forms the foundation of action. Chhatrapati Shivaji initiated the establishment of Hindavi Swarajya based on this Sankalpa (pledge). He anointed his blood on the pind of Rohideshwar as a symbol of his pledge. Inspired by this, Shivjayanti was celebrated in 2020 with the innovative Shiv Sankalp (Shivs Resolve) initiative. The resolve was to make Marathwada, including 1.5 lakh Mavalis, drought-free and establish a strong and inclusive society that respects women. <br>Shivjayanti featured a bike rally of girls, Shiva Abhishek performed by twelve bull tamers, and a procession from Jijau Srishti to the statue of Chhatrapati Shivaji Maharaj. The event saw the active participation of people from all castes, including gondhali teams, bhajan and kirtan teams, lezim teams, and dhol tasha teams. Shirkhurma was distributed to Lord Shivas devotees on behalf of the Muslim community at Chhatrapati Shivaji Maharaj Chowk. The immersion ceremony concluded with paying homage to Neelakanteswarar, the village deity.',

        // shivjayanti 2021
        soc14: 'Shivjayanti 2021: Cultural Shiv Jayanti',
        socti141: "Cultural programs were organized over two days in the midst of the pandemic.",
        socti142: 'All programs were conducted in compliance with COVID-19 safety measures, including mask-wearing and hand sanitization.',
        socti143: 'On the evening preceding Shiv Jayanti, a musical event featuring the renowned lyricist and singer Avadhoot Gupte, titled Shivgarjana, was organized',
        socti144: 'On Shiv Jayanti day (19th Feb), a flower shower was performed with a helicopter, along with a Shiv Abhishek ceremony.',
        socti145: 'The celebrated actor Bharat Jadhav staged the play Sahi Re Sahi.',
        socti146: 'The celebrated actor Bharat Jadhav staAll programs adhered to the guidelines and regulations related to COVID-19.',

        // shivjayanti 2022
        soc15: 'Shivjayanti 2022: Eternal Shiv Jayanti',
        socti151: 'The concept of eternal service is considered while contemplating Eternal Shiv Jayanti.',
        socti152: 'An approximately 11,000 square foot oil painting of Chhatrapati Shivaji, recognized as a world-renowned masterpiece.',
        socti153: 'Inclusion in the Guinness Book of World Records for the oil painting.',
        socti154: 'The creation of this extraordinary oil painting involved the application of 450 liters of oil color and varnish coating in just 16 days.',
        socti155: 'A Blood Donation Maha Yagna was organized, with 585 villagers donating blood.',
        socti156: 'A proposal to rename Nilangas Shivaji Square as Chhatrapati Shivaji Maharaj Square, with the unveiling of a new nameplate.',
        socti157: 'A research-oriented lecture by the renowned Shivaji scholar, Prof. Gangadhar Banbare, on the subject of The Warrior King Chhatrapati Shivraya.',
        socti158: 'Tree planting in every village panchayat through service-oriented organizations affiliated with the Nilanga Voters Association.',

        // shivjayanti 2023
        soc16: 'Shivjayanti 2023: Jijavuvandan Shivjanmohotsav',
        socti1601: 'Rajmata Jijabai, the mother of Chhatrapati Shivaji, was the inspiration behind the establishment of Shivajis kingdom. Her commitment to empowering women in society and spreading awareness about her legacy led to the initiation of the Jijau Vandana Shiv Jayanti Utsav.',
        socti1602: 'The highlight of this event is an 81,393 square foot crop art depicting Chhatrapati Shivajis statue.',
        socti1603: 'Renowned scholar Dr. Nandatai Patil will deliver a lecture on the topic "I am Jijavu speaking.',
        // adrsh gao
        soch10: "Nagatirthwadi: A New Digital Village with a Vision",
        soct10: "Nagateerthwadi is a small village in Latur district, Maharashtra, India, situated in a remote location, away from the city's hustle and bustle. This village was rarely discussed in the past, with people leading ordinary lives and lacking modern facilities like other villages. <br>However, Nagteerthwadi has now gained prominence throughout Maharashtra as it is considered a model village in the state. It holds several unique distinctions:",
        socul10: 'Nagateerthwadi became the first village to achieve 100 percent vaccination during the COVID-19 pandemic.',
        socli10o1: 'It is recognized as a Technosavi village, providing free WiFi facilities to its residents.',
        socli10o2: 'The village has embraced digital transactions for all activities, establishing itself as a Paperless Gram Panchayat.',
        socli10o3: 'The villagers express their love for the nation by singing patriotic songs every morning, earning the title of Rashtrapremi Gaon (Patriotic Village)',
        socli10o4: 'These accomplishments are attributed to the efforts of the villages citizens and the support of the Akka Foundation. Founder President Shri. Arvind Patil Nilangekar has adopted this village, leading to the implementation of numerous projects funded by the organization and government schemes. Thanks to these initiatives, Nagteerthwadi has earned the reputation of being a model village in Maharashtra.',
        socli10o5: 'Additional achievements include exemplary work in water conservation, winning a reward of 5 lakh rupees in the Water Cup competition organized by the Paani Foundation, modernization and beautification of schools with digital technology, 24/7 free Wi-Fi facility, and the promotion of digital practices through a Paperless Gram Panchayat and the construction supported by multiple farmers organizations.',
        // corona /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        soch11: "Corona Work",
        soct11: "COVID-19 is one of the deadliest pandemics in human history. The virus originated in the Chinese city of Wuhan and has since spread worldwide, claiming the lives of countless people. In the spirit of humanity, the 'Akka Foundation' extended its assistance by making numerous efforts to serve the public during this crisis. ",
        socul11: 'These efforts included:',
        socli11o1: 'Providing food to 25,000 needy citizens during the COVID-19 pandemic.',
        socli11o2: 'Distributing meals to small businesses, impoverished patients, and their relatives through food volunteers.',
        socli11o3: 'Distributing 5 BiPAP ventilators and 30 oxygen concentrators to hospitals as needed.',
        socli11o4: 'Establishing COVID Care Centers with more than 100 beds.',
        socli11o5: 'Launching a self-reliant "N-ICU" for children through the Akka Foundation after recognizing the potential for a third wave.',
        socli11o6: 'Organizing blood donation camps for COVID patients, establishing war rooms for top-level assistance, distributing sanitizers and arsenic album to citizens, and organizing blood donation camps to ensure an adequate supply of blood.',
        socli11o7: 'Encouraging sugar factories to participate in the oxygen production project.',
        socli11o8: 'Establishing a "Vaccination Task Force" to expedite vaccination during the COVID period, with the participation of 800 volunteers.',
        socli11o9: 'Under this task force, vaccinating 12,000 disabled citizens at their homes. ',
        socli11o10: 'This awe-inspiring initiative drew global media attention, including coverage from Maharashtra. To bolster this noble cause, the former leader of opposition, Mr. Devendra Fadnavis, extended support and guidance, engaging in a virtual interaction with the task force, offering information and encouragement.',
        socli11o11: 'Being the first village in Marathwada and the second in Maharashtra to achieve successful 100% vaccinations.',
        // 12.	Help For Farmers  ////////////////////////////////////////////////////////////////////////////////////////////////
        soch120: "A Helping Hand for Farmers",
        soch12: "Helping families of suicide victims:",
        soct12: "Farmers are the support of our entire society. But unfortunately, there comes a time when they commit suicide. To prevent this kind of thing from happening to the farmer brothers, many service activities are being implemented under the leadership of Mr Arvind Patil Nilangekar.",
        cohhead12o1: "Support for Agricultural Literature:",
        cohshead12o1: "To address the challenges faced by farming families, the Akka Foundation provides essential seeds, agricultural literature, guidance, and various forms of assistance when needed. To date, the foundation has assisted over 1500 farming families.",
        cohhead12o2: "Assistance for Farming Families Affected by Suicide:",
        cohshead12o2: "Cases of farmer suicides in Maharashtra are deeply distressing, and it is everyone's responsibility to support these affected families. In line with this sense of duty, the foundation offers support to the families of farmers who have taken their own lives by providing livestock assistance to help sustain their livelihoods.",
        cohhead12o3: "Various Plans and Assistance: ",
        cohshead12o3: "Various organizations and government aid are provided to support farming families in need. The Akka Foundation, through its volunteers, also offers guidance and support to farmer families in this regard.",
        // 13.	Activities for Brothers with Disabilities (Akka Foundation) /////////////////////////////////////////////////////////////////////////////////////////////////
        soch13: "Activities for Individuals with Disabilities (Akka Foundation)",
        soct13: "To empower differently-abled citizens and improve their quality of life, Mr. Arvind Patil Nilangekar initiated the Empathy program through the Akka Foundation. This innovative initiative begins by registering differently-abled citizens in the Latur district and providing them with necessary literature for free, along with employment opportunities. Efforts are also made to help them access the benefits of government schemes meant for differently-abled citizens.",
        socul13: 'Activities Under the Empathy Program:',
        socli13o1: '13,371 differently-abled citizens have benefited from government schemes.',
        socli13o2: '8,797 citizens have received free literature assistance.',
        //About  //Nita
        personalinform: 'Personal Information',
        Name: 'Name',
        Vilg: 'Village',
        Village: ' Nilanga (Tal. Nilanga Dist. Latur)',
        Birth: '9th March 1983',
        DOB: 'Date Of Birth ',
        Education: 'Education',
        Degree: ' PG Diploma in Urban & Regional Planning (Australia)',
        Presently: 'Presently Working',
        English: 'English Bio',
        Stock: ' Stock Images',
        Marathi: 'Marathi Bio',
        // working: ' State Secretary, Bharatiya Janata Party, Maharashtra President, Akka Foundation',
        fname: 'Mr. Arvind Diliprao Patil Nilangekar',
        Meet: 'Meet Arvind Bhaiya',
        // heroaboutdetail: ' Marathwada` s Latur district has a history of producing great leaders for our country. The Nilangekar family from this place is one of them, who have dedicated their lives to social service and their country. They have given us a young and dynamic leader, Arvind Patil Nilangekar. Arvind Patil Nilangekar has a family legacy that he upholds exceptionally well through his easy-going and friendly personality. Starting his work at the grassroots level, Arvind bhaiya began working for the society with MLA Sambhaji Patil Nilangekar. Today, his dedication and unwavering love for his country have earned him the position of Maharashtra State Secretary in the biggest political party, Bharatiya Janata Party, in the country.',
        legacy: 'Legacy',
        legacy1: 'The legacy of social work has been passed down from generation to generation in the Nilangekar family. Late Diliprao (Bhausaheb) Patil Nilangekar, Arvind Patil`s father, was known throughout Latur district for his service and philanthropy. No one left empty-handed when they came to him. He always thought of helping the poor with all his might. Therefore, people from the surrounding areas, including Nilanga, held him in high regard as their pillar of support. Parents are the best role models for their children, and Arvind Patil started his early education and life following his father`s ideas. Unfortunately, his father passed away, leaving behind valuable life lessons and experiences. Arvind started his next phase of life with the strength of his memories and rituals. Today, he begins each day with a visit to his father`s tomb, believing that his father`s blessings are always with him. With love for his father and a deep commitment to helping the less fortunate, Arvind Patil continues his father`s legacy. Recognizing the qualities of Diliprao in his son, the people of Latur, including Nilange, affectionately call him Bhausaheb like Arvind Patil. This title bestowed by the people testifies to the son`s alignment with his father`s achievements.',

        // home
        heroabout: 'Marathwada` s Latur district has a history of producing great leaders for our country. The Nilangekar family from this place is one of them, who have dedicated their lives to social service and their country. They have given us a young and dynamic leader, Arvind Patil Nilangekar. He upholds his family legacy exceptionally well through his easy-going and friendly personality. Arvind bhaiya began his journey at the grassroots level, working for the society alongside MLA Sambhaji Patil Nilangekar. Today, his dedication and unwavering love for his country have earned him the position of Maharashtra State Secretary in the largest political party, Bharatiya Janata Party, in the country.',
        // Samarth
        boothp: "It is a campaign aimed at strengthening the booth-level organization of a political party. It serves as a medium for coordination between party leadership, workers, and the general public. The primary objective is to enhance the organization's structure and importance at the grassroots level. When utilized effectively, booth-level organization can become a significant social, cultural, and community platform for various beneficial activities. <br><br>The campaigns goal is to strengthen each booth, leading to the overall strengthening of the party. This concept has been emphasized by respected leaders like Prime Minister Narendra Modi and former national president of the party, Amit Shah. The responsibility of coordinating the Samarth Booth Abhiyan in the state was assigned to Shri Arvind Patil Nilangekar on  21 June 2021. <br><br>Under the guidance of former Chief Minister Devendra Fadnavis and former State President Chandrakant Patil, the Samarth Booth Abhiyan was initiated, commemorating the birth anniversary of the founder of the Rashtriya Swayamsevak Sangh (RSS), Dr. Shyamaprasad Mukherjee, from June 6 to December 25, 2021. The campaign aimed to empower 19,000 power centers and 97,000 booths in the state.<br><br>",

        boothli: "Notable Features of the Campaign",

        boothli1: "Empowerment of 86,130 booths through this campaign during the period from July 6 to September 17 and from September 17 to December 25, 2021.",
        boothli2: "Appointment of 19,169 Shakti Kendra leaders.",
        boothli3: "Appointment of 86,466 booth leaders.",
        boothli4: "Information about 9,000 committees associated with the opposing side reached more than 1.4 million committee members after the campaign.",
        boothli5: "Department-level meetings were held in Western Maharashtra, Konkan, Northern Maharashtra, Marathwada, and Vidarbha regions as part of the campaign.",
        boothli6: "Meetings for booth empowerment under the Samarth Booth Campaign - 2",

        boothcounterh: 'Success of Samarth Booth Campaign',
        samarthcount1: 'Head of Shaktikendra - 19169',
        samarthcount2: 'Booth Heads - 86466',
        samarthcount3: 'Booth committee - 86466',
        samarthcount4: 'of power centers empowering',
        samarthcount5: 'of booth heads appointments',
        samarthcount6: 'participation and Forming booth committees.',

        soch8: "The Samarth Booth Abhiyan",

        Sashaktikaranp: "Booths serve as a medium of coordination between the party leadership, workers, and common citizens. Therefore, the work of each booth is crucial for the organizational development of the party. When properly utilized, a booth can transform into a social, cultural, and organized platform for community engagement and beneficial activities. Recognizing that empowering every booth translates to strengthening the party in the truest sense, the Honorable Prime Minister of the country, Shri Narendra Modi, emphasized that every booth symbolizes the party's strength. Narendra Modi holds the position of the national president of the party. The central campaign 'Booth Sashaktikaran' was launched in May 2022, initiated by Jagat Prakash Nadda ji.<br><br>This campaign directed public representatives to visit every booth, interact with the workers and citizens, and raise awareness about the pro-people initiatives of the central government.In Maharashtra, the campaign was launched under the leadership of Chandrashekhar Bawankule. Initially, the state was tasked with strengthening a total of 5311 booths in 106 Assembly and 23 Lok Sabha constituencies, excluding Mumbai.<br><br>However, thanks to the dedicated efforts of respected Bawankule Sahib, this number expanded to 252 Vidhan Sabha and 42 Lok Sabha constituencies. Under Bawankule Sahib's guidance and follow-up, a total of 14 thousand booths were successfully fortified in a short span of time. This endeavor marked the pinnacle of organizational work within the state.Praise from the central government:<br><br/>The central government's objective was to empower 1 lakh booths nationwide. Maharashtra alone contributed by adding 14 thousand booths to this organizational network. The central government has expressed its appreciation for the work carried out by the Maharashtra BJP. Furthermore, they acknowledged Maharashtra's significant contribution to achieving the target of Rs 1 lakh",


        soculs8: "Notable Features of the Campaign:",
        anandic1: 'Students',
        anandic2: 'Schools',
        anandic3: 'Workshops',
        anandic4: 'Days',


    }
]
export default englishdata;