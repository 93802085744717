import React from 'react'
import './about.css';
import image from '../images/arvindp-1.webp'
import { BiSolidDownload } from "react-icons/bi"
const About = (props) => {
  return (
    <>
      {props.data.map((data, index) => {
        return (
          <div className="about-parent-v1 parent" key={index}  >
            <div className="about-container container">
              <div className="left">
                <h1>{data.Meet}</h1>
                <div className='card-parent'>
                  <div className='card-container'>
                    <div className='card'>
                      <div className='card-headding'>
                        <h2>{data.personal}</h2>
                      </div>
                      <div className='card-text'>
                        <div className='card-text-info'>
                          <span className='name-title'>{data.Name}</span><p> :  {data.fname} </p>
                        </div>
                        <div className='card-text-info'>
                          <span className='name-title'>{data.Vilg}</span><p>:  {data.Village}</p>
                        </div>
                        <div className='card-text-info'>
                          <span className='name-title'>{data.DOB}</span><p>: {data.Birth}</p>
                        </div>
                        <div className='card-text-info'>
                          <span className='name-title'>{data.Education}</span><p>:  {data.Degree}</p>
                        </div>
                      </div>
                    </div>
                    <div className='gap1'></div>
                    <div className='card'>
                      <div className='card-headding'>
                        <h2>{data.Presently}</h2>
                      </div>
                      <div className='card-text'>
                        <p>{data.working1}</p>
                      </div>
                    </div>
                    {/* button cards */}
                    <div className="gap1"></div>
                    <div className="card btncards">
                      <div className="btn dbtn"><a href="https://drive.google.com/file/d/12ySJx3OoIdu4Nik-RoIzADFZVCAw1-QH/view" target="_blank"><BiSolidDownload />English BIO</a></div>
                      <div className="btn dbtn"><a href='https://stock.arvindpatilnilangekar.com' target='_blank'><BiSolidDownload />{props.state ? "फोटो" : 'Stock Images'}</a></div>
                      <div className="btn dbtn"><a href='https://drive.google.com/file/d/1sZg0VoJnksbrYa7wWfsIRCM5nkXHbp52/view' target='_blank'><BiSolidDownload />{data.bio}</a></div>
                    </div>
                  </div>
                </div>

                <div className='left-content'>
                  <p>{data.heroaboutdetail1}</p>
                </div>
              </div>
              <div className="right"  >
                <div className='right-container'>
                  <img src={image} alt="About Arvind Patil Nilangekar" />
                </div>
              </div>

            </div>

            {/* //legacy-container */}

            <div className="legacy-container">
              <div className="legacy">
                <h2>{data.legacy}</h2>
                <p>{data.legacy1}</p>
              </div>
            </div>
          </div>


        )
      })}
      <div className='gap'></div>


    </>
  )
}

export default About