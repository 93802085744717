import React from 'react'
import "./hero.css"
import mainhero from "../images/apn-hero.webp"
import { Link } from 'react-router-dom';

const Hero = (props) => {
    return (
        <>
            {props.data.map((data,index) => {
                return (
                    <div key={index}>
                        <div className="bio1">

                            <div className="bio1_under">
                                <div className="left1">
                                    <img src={mainhero} alt="Arvind Patil Nilangekar " />
                                </div>
                                <div className="right1">

                                    <h1>{data.fullname}</h1>
                                    <p>{data.herodesc}</p>
                                    <br /><br />
                                    <p className='rs1'>{data.heroabout}</p>
                                    <br /><br />
                                    <Link to='/about' className='btn'>{data.readmore}...</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </>
    )
}

export default Hero