
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Mainpage from './components/Mainpage';
import Contact from './components/Contact';
import English from "./contents/English"
import Marathi from './contents/Marathi';
import { useState } from 'react';
import ScrollToTop from './components/ScrollToTop';
import Footer from './components/Footer';
import About from './components/About';
import ChangeButton from './components/tools/ChangeButton';
import BlogLayout from './components/BlogLayout';
import SocialWork from './components/SocialWork';
import PaperCutout from "./components/PaperCutout"
import Gallery from './components/Gallery';
import Blog from './components/Blog';
import Privacy from './components/Privacy';
import Disclaimer from './components/Disclaimer';
import FloatingContact from "./components/tools/FloatingContact"
import HeaderTwo from './components/HeaderTwo';
import Hero from './components/Hero';
import { Helmet } from 'react-helmet';
import Samarth from './components/Samarth';
function App() {

  const [jsonData, setJsonData] = useState(English);
  const [state, setstate] = useState(false)

  const toggleJsonData = () => {
    setJsonData(jsonData === English ? Marathi : English);
    setstate(!state)
    // alert("hello")
  };
  return (
    <BrowserRouter>
      <Helmet>
        <title>Arvind Patil Nilangekar- Akka Foundation, Nilanga Conveyor
        </title>
        <meta name='description' content='Welcome to the home page of Arvind Patil Nilangekar. Explore his achievements, projects, and contributions in various fields.
'/>
        <meta name='keywords' content='arvind patil nilangekar, state secratery, bhartiya janata party maharastra, vaccination task force, akka foundation, samarth booth abhiyan,arvind bhaiyya
'/>
        
      </Helmet>
      <ScrollToTop />
      <FloatingContact />

      <ChangeButton setdata={toggleJsonData} state={state} />
      <HeaderTwo state={state} setstate={setstate} data={jsonData} />
      <Routes>
        <Route path="/" element={<Mainpage data={jsonData} state={state} setstate={setstate} />} />
        <Route path="/gallery" element={<Gallery state={state} data={jsonData} />} />
        <Route path="/contact" element={<Contact data={jsonData} state={state} setstate={setstate} />} />
        <Route path="/news" element={<PaperCutout data={jsonData} state={state} setstate={setstate} />} />
        <Route path="/about" element={<About data={jsonData} state={state} setstate={setstate} />} />
        <Route path="/blogs/" element={<Blog data={jsonData} state={state} setstate={setstate} />} />
        <Route path="/blogs/:id" element={<BlogLayout data={jsonData} state={state} setstate={setstate} />} />
        <Route path="/social/" element={<SocialWork data={jsonData} state={state}/>} />
        <Route path="/hero" element={<Hero />} />
        <Route path="/privacy" element={<Privacy data={jsonData} state={state} setstate={setstate} />} />
        <Route path="/disclaimer" element={<Disclaimer data={jsonData} state={state} setstate={setstate} />} />
        <Route path="/samarth-booth-abhiyan" element={<Samarth data={jsonData} state={state} setstate={setstate} />} />
      </Routes>
      <Footer state={state} setstate={setstate} data={jsonData} />
    </BrowserRouter>
  );
}

export default App;
