import React from 'react'
import "./changebutton.css"
import {LuLanguages} from "react-icons/lu"
const ChangeButton = (props) => {
    return (
        <div className='change_button'>
            <button onClick={props.setdata}>
            <LuLanguages/>
            </button>
        </div>
    )
}

export default ChangeButton