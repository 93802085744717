import React, {useState } from 'react'
import { Link } from 'react-router-dom'
import "./headertwo.css"
import { useLocation } from 'react-router-dom';
import { RxHamburgerMenu, RxCross1 } from "react-icons/rx"
const HeaderTwo = (props) => {
  const [toggle, settoggle] = useState(false)
  const location = useLocation()
  return (
    <>
      {props.data.map((data,index) => {
        return (
          <div className='theader-Wrapper' key={index}>
            <div className="theader">
              {/* left setion */}
              <div className="tleft">
                <Link to="/about" className={location.pathname === '/about' ? "active" : "hover"}>{data.about}
                </Link>
                <Link className={location.pathname === '/social' ? "active" : "hover"} to="/social">{data.social}</Link>
                <Link className={location.pathname === '/news' ? "active" : "hover"} to="/news">{data.news}</Link>
              </div>
              {/* left secton end */}
              {/* logo part*/}
              <Link to="/" className="tlogo">
                {/* <div className="logo-img">
            <img src={logo} alt="" />
          </div> */}
                <span  className='splogo' >{data.logofname} <br /> {data.logolname} </span>
              </Link>
              {/* logo part end */}
              {/* right section */}
              <div className="tright">
                <Link className={location.pathname === '/media' ? "active" : "hover"} to="https://akkafoundation.in/" target="_blank">{data.akka}</Link>
                <Link className={location.pathname === '/gallery' ? "active" : "hover"} to="/gallery">{data.gallery}
                </Link>
                <Link to="/blogs" className={location.pathname === '/blogs' ? "active" : "hover"}
                >{data.blogs}</Link>
              </div>
              {/* right section end */}

              {/* menu */}

              <div className="tmenubutton" onClick={() => settoggle(!toggle)}>
                {toggle ? <RxCross1 /> : <RxHamburgerMenu />}
              </div>
              {/*  */}
            </div>
            {/* ----------------------------------------------------------toggled menu---------------------------------------------------------------- */}
            <div className="ttoggled-menu" style={{ left: `${toggle ? '0%' : '100%'}` }} >
              <div className="tinner_links_toggled" id='anchor'>
                <Link className={location.pathname === '/about' ? "active" : "hover"} to="/about" onClick={() => settoggle(!toggle)} > {data.about}</Link>
                <Link className={location.pathname === '/social' ? "active" : "hover"} to="/social" onClick={() => settoggle(!toggle)}>{data.social}</Link>
                <Link className={location.pathname === '/news' ? "active" : "hover"} to="/news" onClick={() => settoggle(!toggle)}>{data.news}</Link>
                <Link className="hover" to="https://akkafoundation.in/" onClick={() => settoggle(!toggle)}>{data.akka}</Link>
                <Link className={location.pathname === '/gallery' ? "active" : "hover"} to="/gallery" onClick={() => settoggle(!toggle)}>{data.gallery}</Link>
                <Link className={location.pathname === '/blogs' ? "active" : "hover"} to="blogs" onClick={() => settoggle(!toggle)}>{data.blogs}</Link>
              </div>
            </div>
            {/* ----------------------------------------------------------toggled menu---------------------------------------------------------------- */}
          </div>
        )
      })}
    </>
  )
}

export default HeaderTwo