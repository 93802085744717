import React from 'react'
import './floatingcontact.css'
import {IoIosCall} from "react-icons/io"
import { Link } from 'react-router-dom'
const FloatingContact = () => {
  return (
    <div className='floating_contact_wrapper'>
        <Link to='/contact' className='con_btn'><IoIosCall/></Link>
    </div>
  )
}

export default FloatingContact