import React from "react";
import "./videogallery.css";
import ReactPlayer from "react-player";
import 'aos/dist/aos.css';
const VideoGallery=(props)=> {
  return (
    <>
      <div className='gallery-parent '>
        <div className='gallery-container'>

          <div className='gallery-box'>
          {props.data.map((data) => { return (<h2 data-aos="zoom-in" data-aos-delay="200">{data.video} <span className="gallery">{data.gallery}</span></h2>) })}
            <div className='first-video'  >
              <ReactPlayer className="video_player" controls url='https://www.youtube.com/watch?v=Oy4z0qPeqvM&t=30s' />
            </div>
            <div className='first-video'  >
              <ReactPlayer className="video_player" controls url='https://www.youtube.com/watch?v=nmH-mJChUJs&t=1s' />
            </div>
            <div className='first-video' >
              <ReactPlayer className="video_player" controls url='https://www.youtube.com/watch?v=xjn_xmLpeR8' />
            </div>
            <div className='first-video'  >
              <ReactPlayer className="video_player" controls url='https://www.youtube.com/watch?v=muGNGoJ9Gws' />
            </div>
            <div className='first-video'  >
              <ReactPlayer className="video_player" controls url='https://www.youtube.com/watch?v=Ix-dUeYndf4&t=6s' />
            </div>
            <div className='first-video'  >
              <ReactPlayer className="video_player" controls url='https://www.youtube.com/watch?v=HiqVzkDH-FY' />
            </div>

            <div className='first-video'  >
              <ReactPlayer className="video_player" controls url=' https://www.youtube.com/watch?v=xNQKF1wf5VA' />
            </div>
            <div className='first-video' >
              <ReactPlayer className="video_player" controls url='https://www.youtube.com/watch?v=Ox8js3FUPIU' />
            </div>
            <div className='first-video' >
              <ReactPlayer className="video_player" controls url='https://www.youtube.com/watch?v=wtpC2D6tYY4' />
            </div>
            <div className='first-video'>
              <ReactPlayer className="video_player" controls url='https://www.youtube.com/watch?v=3POoOb2xQgo' />
            </div>
            <div className='first-video'>
              <ReactPlayer className="video_player" controls url='https://www.youtube.com/watch?v=KYN0SOXFQF0' />
            </div>
            <div className='first-video' >
              <ReactPlayer className="video_player" controls url='https://www.youtube.com/watch?v=XC0G6O9_HFI' />
            </div>

          </div>
          <div className='gap-gallery'></div>
        </div>
      </div>
      {/* //// */}
    </>
  );
}

export default VideoGallery