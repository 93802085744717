import React, { useEffect, useState } from 'react'
import "./herocarousel.css"
import AOS from 'aos';
import 'aos/dist/aos.css';
const HeroCarousel = (props) => {
    const [Class, setClass] = useState('carousel_parent toggledclass')
    useEffect(() => {
        AOS.init();
    }, [Class])
    return (

        <div className={Class}>
            <div className="slides"></div>
            <div className="overlay-top">
                <div className="o_inner_box">
                    <div className="otop">
                        {props.state ? <div className="textslide slide1" >वसा राष्ट्राकार्याचा</div> : <div className="textslide slide1" >Patriotic Legacy </div>}
                        {props.state ? <div className="textslide slide2" >बांधिलकी समाजाच्या हिताशी</div> : <div className="textslide slide2" >
                        Commitment to the Community</div>}
                        {props.state ? <div className="textslide slide3" >कृती...लोककल्याणाची</div> : <div className="textslide slide3" >Actions For Public Welfare</div>}
                        {props.state ? <div className="textslide slide4" > नेतृत्व नव्या विचारांचे </div> : <div className="textslide slide4" > Leadership for Innovation </div>}
                    </div>
                    <div className="obottom">
                        <div className="oline1"></div>
                        <div className="onav">
                            <span onClick={() => { setClass('carousel_parent toggledclass') }}></span>
                            <span onClick={() => { setClass('carousel_parent toggledclass2') }}></span>
                            <span onClick={() => { setClass('carousel_parent toggledclass3') }}></span>
                            <span onClick={() => { setClass('carousel_parent toggledclass4') }}></span>
                        </div>
                        <div className="oline2"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroCarousel